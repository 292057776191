
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { Actions, Getters } from '@/store/enums/VerifyAccountEnums';
import toasts from '@/utils/toasts';

export default defineComponent({
  name: 'verify-account-code-form',
  emits: ['handleCodeInput'],
  data: () => ({
    loading: false,
  }),
  computed: {
    ...mapGetters({
      token: Getters.GET_VERIFY_ACCOUNT_TOKEN,
      errors: Getters.GET_ACTION_ERROR,
    }),
  },
  methods: {
    ...mapActions({
      requestNewToken: Actions.REQUEST_NEW_TOKEN,
    }),
    clickRequestBtn() {
      if (this.loading) return;
      this.loading = true;

      this.requestNewToken()
        .then(() => {
          this.loading = false;

          toasts.success(
            'Link successfully submitted. Please check your email.'
          );
        })
        .catch(() => {
          const { errors, messages } = this.errors;
          toasts.error(errors, messages);
          this.loading = false;
        });
    },
  },
});
